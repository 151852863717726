import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { TabView, TabPanel } from "primereact/tabview";
import ContectPersonAdd from "./ContectPersonAdd";
import { PriviladgeContext } from "./../../context/priviladge-context";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import EmailConfigAdd from "./EmailConfigAdd";
var _ = require("lodash");
function CustomerAdd(props) {
  var [obj, setObj] = useState({ customTextForRegistration: "Registration" });
  var [disableFile, setDisableFile] = useState(false);
  const growl = useRef(null);
  var [contectPersons, setContectPersons] = useState([]);
  var [activeIndex, setActiveIndex] = useState(0);
  const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
  var [disableContentFile, setDisableContentFile] = useState(false);
  var [disableBrochureFile, setDisableBrochureFile] = useState(false);
  var [trackNTrace, setTrackNTrace] = useState(false);
  var [emailConfig, setEmailConfig] = useState({});
  const httpService = new HttpService();

  useEffect(() => {
    if (
      localStorage.getItem("track&Trace") != undefined &&
      localStorage.getItem("track&Trace")
    )
      setTrackNTrace(true);
  }, []);

  const handleChange = (e, type) => {
    let val = e.target.value;
    if (e.target.name === 'micrositeEnable' && val == false) {
      setObj(current => {
        const { emailConfig, ...rest } = current;
        return rest;
      });
    }
    setObj({ ...obj, [e.target.name]: val });
    return val;
  };

  const onRemoveImg = () => {
    _.unset(obj, "file");
    setDisableFile(false);
  };

  const cancel = () => {
    props.history.push("customer_list");
  };

  var saveContectPersons = (data) => {
    reset({ ...obj });
    setContectPersons(data);
    setActiveIndex(0);
  };


  var saveEmailConfigSettings = (data) => {
    reset({ ...obj });
    setEmailConfig(data)
    setActiveIndex(0);
  };

  const onTabChange = (index) => {
    setActiveIndex(index);
    if (index == 0) {
      reset({ ...obj });
    }
  };

  const onFileSelect = (e, name) => {
    let fileArray = [];
    if (obj.files !== undefined && obj.files !== "") {
      fileArray = [...obj.files];
    }

    var file = e.files[0];
    const fileName = e.files[0].name;
    const lastDot = fileName.lastIndexOf(".");

    const ext = fileName.substring(lastDot + 1);
    file.originalFilename = name + "." + ext;
    fileArray.push(file);
    setObj({ ...obj, files: fileArray });
  };

  const onRemoveFile = (e) => {
    if (obj.files !== undefined && obj.files.length > 0) {
      let index = obj.files.findIndex((x) => x.name === e.file.name);
      if (index > -1) {
        obj.files.splice(index, 1);
      }
    }
  };

  const save = (data) => {

    obj = clean(obj);
    if (obj?.micrositeEnable && Object.keys(emailConfig).length === 0) {
      showMessage(
        "error",
        "Error Message",
        "Please Configure Email Settings as customized microsite is turned on.",
        growl
      );
    } else {
      if (trackNTrace) obj.companyType = "Business Partner";
      else obj.companyType = "Customer";
      let formData = new FormData();
      var contactPersons = [];
      if (contectPersons.length > 0 && contectPersons != undefined) {
        var cIndex = 0;

        for (var pair of contectPersons) {
          contactPersons["contectPersons[" + cIndex + "].name"] =
            contectPersons[cIndex].name;
          contactPersons["contectPersons[" + cIndex + "].designation"] =
            contectPersons[cIndex].designation;
          contactPersons["contectPersons[" + cIndex + "].email"] =
            contectPersons[cIndex].email;
          contactPersons["contectPersons[" + cIndex + "].mobile"] =
            contectPersons[cIndex].mobile;

          cIndex++;
        }
      }

      if(emailConfig != undefined && Object.keys(emailConfig).length > 0){
        for (let key in emailConfig) {
          if (emailConfig.hasOwnProperty(key)) {
            formData.append(`emailConfig.${key}`, emailConfig[key]);
          }
        }
      }

      for (const property in obj) {
        //   if (obj[property] !== undefined) formData.append(property, obj[property]);
        if (property === "files") {
          if (obj[property] !== undefined)
            obj[property].forEach((element) => {
              formData.append("file", element, element.originalFilename);
            });
        } else if (obj[property] !== undefined){
          formData.append(property, obj[property]);
        }
      }

      for (const property in contactPersons) {
        if (contactPersons[property] !== undefined)
          formData.append(property, contactPersons[property]);
      }

      trackPromise(
        httpService
          .getApiMultipart("customers", formData)
          .then((response) => {
            cancel();
          })
          .catch((error) => {
            if (checkForError(error)) {
              showMessage(
                "error",
                "Error Message",
                error.response.data.title,
                growl
              );
            }
          })
      );
    }
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          {
            label: trackNTrace ? "Business Partner List" : "Customer List",
            url: trackNTrace ? "#/business_partner_list" : "#/customer_list",
          },
          { label: trackNTrace ? "Business Partner Add" : "Customer Add" },
        ]}
      />
      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-8">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => onTabChange(e.index)}
          >
            <TabPanel header={trackNTrace ? "Business Partner" : "Customer"}>
              <div className="card card-w-title">
                <h1>{trackNTrace ? "Business Partner" : "Customer"}</h1>
                <div className="p-grid p-fluid form-group">
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">business</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.companyName ? "p-error" : ""}
                          name="companyName"
                          defaultValuevalue={obj.companyName}
                          rules={{
                            ...rule.RULE.requiredRule,
                            ...rule.RULE.maxLengthRuleVal100,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Company Name</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.companyName?.message}
                    </span>
                  </div>

                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">phone</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.mobileNo ? "p-error" : ""}
                          type="number"
                          name="mobileNo"
                          value={obj.mobileNo}
                          rules={{
                            ...rule.RULE.minLengthRule10,
                            ...rule.RULE.maxLengthRule15,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Phone No.</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.mobileNo?.message}
                    </span>
                  </div>
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">email</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.email ? "p-error" : ""}
                          name="email"
                          value={obj.email}
                          rules={{ ...rule.RULE.maxLengthRuleVal200 }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Email</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.email?.message}
                    </span>
                  </div>
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">home</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.address ? "p-error" : ""}
                          name="address"
                          value={obj.address}
                          rules={{ ...rule.RULE.maxLengthRuleVal100 }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Address</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.address?.message}
                    </span>
                  </div>

                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">home</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.companyCode ? "p-error" : ""}
                          name="companyCode"
                          value={obj.companyCode}
                          rules={{
                            ...rule.RULE.requiredRule,
                            ...rule.RULE.maxLengthRuleVal100,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Company Code</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.companyCode?.message}
                    </span>
                  </div>
                  {!trackNTrace && <>
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">message</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.messageOnScan ? "p-error" : ""}
                            name="messageOnScan"
                            value={obj.messageOnScan}
                            rules={{
                              ...rule.RULE.requiredRule,
                              ...rule.RULE.maxLengthRuleVal100,
                            }}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Message On Qr Code Scanning</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.messageOnScan?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">message</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.textForMicrosite ? "p-error" : ""}
                            name="textForMicrosite"
                            value={obj.textForMicrosite}
                            rules={{
                              ...rule.RULE.maxLengthRuleVal100,
                            }}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Add Text for MicroSite</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.textForMicrosite?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">android</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.androidAppLink ? "p-error" : ""}
                            name="androidAppLink"
                            value={obj.androidAppLink}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Your Android App Link</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.androidAppLink?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i class="fab fa-apple fa-lg"></i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.iosAppLink ? "p-error" : ""}
                            name="iosAppLink"
                            value={obj.iosAppLink}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Your IOS App Link</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.iosAppLink?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i class="fad fa-browser fa-lg"></i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.websiteLink ? "p-error" : ""}
                            name="websiteLink"
                            value={obj.websiteLink}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Website Link</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.websiteLink?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i class="fab fa-linkedin fa-lg"></i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.linkedinLink ? "p-error" : ""}
                            name="linkedinLink"
                            value={obj.linkedinLink}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>LinkedIn Link</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.linkedinLink?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i class="fab fa-instagram fa-lg"></i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.instragramLink ? "p-error" : ""}
                            name="instragramLink"
                            value={obj.instragramLink}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Instagram Link</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.androidAppLink?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i class="fab fa-youtube fa-lg"></i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.youtubeLink ? "p-error" : ""}
                            name="youtubeLink"
                            value={obj.youtubeLink}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>YouTube Link</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.youtubeLink?.message}
                      </span>
                    </div>

                    <div className="p-grid p-col-10">
                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.websiteLinkEnable}
                            name={"websiteLinkEnable"}
                            inputId={obj.websiteLinkEnable}
                            ariaLabelledBy={obj.websiteLinkEnable}
                            value={obj.websiteLinkEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.websiteLinkEnable}
                            className="p-checkbox-label merginLeft15"
                          >
                            Enable Website
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.websiteLinkEnable?.message}
                        </span>
                      </div>

                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.linkedinLinkEnable}
                            name={"linkedinLinkEnable"}
                            inputId={obj.linkedinLinkEnable}
                            ariaLabelledBy={obj.linkedinLinkEnable}
                            value={obj.linkedinLinkEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.linkedinLinkEnable}
                            className="p-checkbox-label merginLeft15"
                          >
                            Enable Linkedin
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.linkedinLinkEnable?.message}
                        </span>
                      </div>
                    </div>

                    <div className="p-grid p-col-10">
                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.instargramLinkEnable}
                            name={"instargramLinkEnable"}
                            inputId={obj.instargramLinkEnable}
                            ariaLabelledBy={obj.instargramLinkEnable}
                            value={obj.instargramLinkEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.instargramLinkEnable}
                            className="p-checkbox-label merginLeft15"
                          >
                            Enable Instagram
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.instargramLinkEnable?.message}
                        </span>
                      </div>

                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.youtubeLinkEnable}
                            name={"youtubeLinkEnable"}
                            inputId={obj.youtubeLinkEnable}
                            ariaLabelledBy={obj.youtubeLinkEnable}
                            value={obj.youtubeLinkEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.youtubeLinkEnable}
                            className="p-checkbox-label merginLeft15"
                          >
                            Enable YouTube
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.youtubeLinkEnable?.message}
                        </span>
                      </div>
                    </div>

                    <div className="p-grid p-col-10">
                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.productContentUrlEnable}
                            name={"productContentUrlEnable"}
                            inputId={obj.productContentUrlEnable}
                            ariaLabelledBy={obj.productContentUrlEnable}
                            value={obj.productContentUrlEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.productContentUrlEnable}
                            className="p-checkbox-label merginLeft15"
                          >
                            Enable Content
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.productContentUrlEnable?.message}
                        </span>
                      </div>
                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.brochureUrlEnable}
                            name={"brochureUrlEnable"}
                            inputId={obj.brochureUrlEnable}
                            ariaLabelledBy={obj.brochureUrlEnable}
                            value={obj.brochureUrlEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.brochureUrlEnable}
                            className="p-checkbox-label merginLeft15"
                          >
                            Enable Brochure
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.brochureUrlEnable?.message}
                        </span>
                      </div>
                    </div>


                    <div className="p-grid p-col-10">
                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.advanceWorkflow}
                            name={"advanceWorkflow"}
                            inputId={obj.advanceWorkflow}
                            defaultValue={true}
                            ariaLabelledBy={obj.advanceWorkflow}
                            value={obj.advanceWorkflow}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.advanceWorkflow}
                            className="p-checkbox-label merginLeft15"
                          >
                            Advance Workflow
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.advanceWorkflow?.message}
                        </span>
                      </div>
                      <div className="p-col-5">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.authenticateViaCode}
                            name={"authenticateViaCode"}
                            inputId={obj.authenticateViaCode}
                            defaultValue={true}
                            ariaLabelledBy={obj.authenticateViaCode}
                            value={obj.authenticateViaCode}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor={obj.authenticateViaCode}
                            className="p-checkbox-label merginLeft15"
                          >
                            Authenticate Via Code
                          </label>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {errors.authenticateViaCode?.message}
                        </span>
                      </div>
                    </div>

                    <div className="p-grid p-col-12">
                      {/* Toggle Button for Enable Microsite */}
                      <div className="p-col-6">
                        <div className="p-inputgroup">
                          <InputSwitch
                            checked={obj.micrositeEnable}
                            name="micrositeEnable"
                            inputId="micrositeEnable"
                            ariaLabelledBy="micrositeEnable"
                            defaultValue={false}
                            value={obj.micrositeEnable}
                            onChange={(event) => handleChange(event)}
                          />
                          <label
                            htmlFor="micrositeEnable"
                            className="p-checkbox-label merginLeft15"
                          >
                            Roto Customized Microsite
                          </label>
                          <span className="errorSpan merginLeft30">
                            {errors.micrositeEnable?.message}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="p-col-8">
                      <label>Upload Company Logo</label>
                      <div className="p-col-8"></div>
                      <div className="p-inputgroup">
                        <Controller
                          className={errors.file ? "p-error" : ""}
                          disabled={disableFile}
                          rules={{}}
                          as={FileUpload}
                          control={control}
                          name="file"
                          onSelect={(e) => onFileSelect(e, "customerLogo")}
                          accept="image/*"
                          onRemove={(e) => onRemoveFile(e)}
                          maxFileSize={1000000}
                          customUpload={true}
                        />
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.file?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <label>Upload fasiculus Logo</label>
                      <div className="p-col-8"></div>
                      <div className="p-inputgroup">
                        <Controller
                          className={errors.file ? "p-error" : ""}
                          disabled={disableFile}
                          rules={{}}
                          as={FileUpload}
                          control={control}
                          name="file"
                          onSelect={(e) => onFileSelect(e, "companyLogo")}
                          accept="image/*"
                          onRemove={(e) => onRemoveFile(e)}
                          maxFileSize={1000000}
                          customUpload={true}
                        />
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.file?.message}
                      </span>
                    </div>
                  </>}
                </div>

                {!trackNTrace &&
                  <>
                    <div className="p-col-8">
                      <label>Upload Content</label>
                      <div className="p-col-8"></div>
                      <div className="p-inputgroup">
                        <Controller
                          className={errors.contentFile ? "p-error" : ""}
                          disabled={disableContentFile}
                          rules={{}}
                          as={FileUpload}
                          control={control}
                          name="file"
                          onSelect={(e) => onFileSelect(e, "productContentUrl")}
                          accept="video/*,.mp3,.gif,image/*"
                          onRemove={(e) => onRemoveFile(e)}
                          maxFileSize={20000000}
                          customUpload={true}
                        />
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.contentFile?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <label>Upload Brochure</label>
                      <div className="p-col-8"></div>
                      <div className="p-inputgroup">
                        <Controller
                          className={errors.brouchureFile ? "p-error" : ""}
                          disabled={disableBrochureFile}
                          rules={{}}
                          as={FileUpload}
                          control={control}
                          name="file"
                          onSelect={(e) => onFileSelect(e, "brochureUrl")}
                          accept=".pdf"
                          onRemove={(e) => onRemoveFile(e)}
                          maxFileSize={20000000}
                          customUpload={true}
                        />
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.brouchureFile?.message}
                      </span>
                    </div>
                  </>
                }
                <div className="p-col-12">
                  <Button
                    icon="pi pi-check"
                    label="Save"
                    onClick={handleSubmit(save)}
                  />
                  {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
                  <Button
                    icon="pi-md-cancel"
                    label="Cancel"
                    className="p-button-secondary"
                    onClick={() => cancel()}
                  />
                </div>
                <div className="p-col-8"></div>
              </div>
            </TabPanel>
            <TabPanel header="Contact Person">
              <ContectPersonAdd
                saveContectPersons={saveContectPersons}
                contectPersons={contectPersons}
                setContectPersons={setContectPersons}
              ></ContectPersonAdd>
            </TabPanel>
            {obj?.micrositeEnable ? (
              <TabPanel header="Email Config Settings">
                <EmailConfigAdd
                emailConfig = {emailConfig}
                  saveEmailConfigSettings={saveEmailConfigSettings}
                ></EmailConfigAdd>
              </TabPanel>
            ) : <TabPanel></TabPanel>}
          </TabView>
        </div>
        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(CustomerAdd);
