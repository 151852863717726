const host = window.location.host;
const protocol = window.location.protocol + "//";
export default {
  //BASEURl: protocol+host+"/token_printing/api/",
  BASEURl: "https://funqid.com/token_printing/api/",
  //  BASEURl: "http://localhost:8080/token_printing/api/",
  // BASEURl: "http://134.209.158.102:8080/goofy_tails/api/",
  //    BASEURl: "http://192.168.1.14:8080/token_printing/api/",
  //  LOGOUT: "http://localhost:8080/fasiculus/logout",
  // IMAGE_BASE_URl: protocol+host+"/image/",

  VIEW_SAMPLE_URI: "https://funqid.com/view_sample/",
  TEMPLATE_URl: "https://funqid.com/template/",
  MASTER_TEMPLATE_URl: "https://funqid.com/master_template/",
  USER_IMAGE_URl: "https://funqid.com/user_image/",

  COMPANY_IMAGE_URL: "https://funqid.com/token_printing/gimage/",

  VIDEO_BASE_URL: "https://funqid.com/token_printing/p/",
  SMART_BIN_URL:"https://funqid.com/token_printing/smart-bin/files/",

  // VIEW_SAMPLE_URI: "http://localhost:8082/token_printing/view_sample/",
  // TEMPLATE_URl: "http://localhost:8082/token_printing/template/",
  // MASTER_TEMPLATE_URl: "http://localhost:8082/token_printing/master_template/",
  // USER_IMAGE_URl: "http://localhost:8082/token_printing/user_image/",

  // COMPANY_IMAGE_URL: "http://localhost:8082/token_printing/gimage/",

  // VIDEO_BASE_URL: "http://192.168.29.38:8081/token_printing/p/",
  // SMART_BIN_URL:"http://localhost:8082/token_printing/smart-bin/files/",

  //    VIEW_SAMPLE_URI:protocol+host+"/view_sample/",
  //    TEMPLATE_URl:protocol+host+"/template/",
  //    MASTER_TEMPLATE_URl:protocol+host+"/master_template/",
  //    USER_IMAGE_URl:protocol+host+"/user_image/",

  //http://localhost:8081/ui/printing/

  QR_CODE: "QR_CODE",
  DATA_MATRIX: "DataMatrix",
  GLOSH: "Guilloche",
  EXPIRY_DATE: "Expiry Date",
  TEXT: "Text",
  TEXT2: "Text2",
  TEXT3: "Text3",
  CODE: "Code",
  DESCRIPTION: "Product Description",
  CUSTOMER: "Customer",
  QUANTITY: "Quantity",
  CARTON: "Carton",
  PO:"PO Number",
  TOKEN_PRINTING: "token_printing",
  COMPANY_NAME: "Emax",
};